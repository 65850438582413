<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card-group m-b-30">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">Upcoming/Today Birthdays</span>
                <h3 class="mt-3 mb-0">{{ upcomingBirthdaysCount }}</h3>
              </div>
              <div>
                <img src="@/assets/img/Birthday.png" alt="icon" style="height: 80px; width: 80px;" >              
              </div>
            </div>
            
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">Upcoming Holidays</span>
                <h3 class="mt-3 mb-0">{{ upcomingHolidaysCount }}</h3>
              </div>
              <div>
                <img src="@/assets/img/Holiday.png" alt="icon" style="height: 80px; width: 80px;" >              
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">Announcements</span>
                  <h3 class="mt-3 mb-0">{{ announcementsCount }}</h3>
              </div>
              <div>
                  <img src="@/assets/img/Annousement.png" alt="icon" style="height: 80px; width: 80px;" >              
              </div>
            </div>
           
          </div>
        </div>

      
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  data() {
    return {
      upcomingBirthdaysCount: 0, 
      upcomingHolidaysCount:0,
      announcementsCount: 0,
    };
  },
  methods: {
     fetchDashboardData() {
      axios
        .get("/dashboard/count") 
        .then((response) => {
          const data = response.data.data;

          this.upcomingBirthdaysCount = data.upcoming_birthdays || 0;
          this.upcomingHolidaysCount = data.upcoming_holidays || 0;
          this.announcementsCount = data.announcements || 0;
        })
        .catch((error) => {
          console.error("Error fetching dashboard data:", error);
          this.upcomingBirthdaysCount = 0;
          this.upcomingHolidaysCount = 0;
          this.announcementsCount = 0;
        });
    },
  
  },
  mounted() {
    this.fetchDashboardData();
  },
};

</script>
